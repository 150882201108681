<template>
  <div>
    <el-container>
      <CommonTree
        :treeTitle="$t('cip.plat.reportcenter.myReport.title.treeTitle')"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :popover-show="true"
        @getNodeClick="treeNodeClick"
        @include-down="includeDown"
      />
      <el-main>
        <head-layout
          :head-title="$t('cip.plat.reportcenter.myReport.title.headTitle')"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <div class="cardBigBox">
          <div class="cardbox" v-for="(item,index) in tableData" :key="index" @click="openIframe(item)">
            <div class="card">
              <div class="cardTop">
                <div class="disflex">
                  <img class="orderImg" src="@/assets/images/order.png">
                  <div class="topContent">
                    <p>{{ $t('cip.plat.reportcenter.myReport.field.reportName') }}：<span>{{ item.reportName }}</span></p>
                    <p>{{ $t('cip.plat.reportcenter.myReport.field.reportTypeName') }}：<span>{{ item.reportTypeName }}</span></p>
                  </div>
                </div>
              </div>
              <div class="cardBottom">
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="$t('cip.plat.reportcenter.report.btn.reportView')"
      :visible.sync="reportViewDialog"
      append-to-body
      width="80%">
      <iframe
        ref="iframe"
        :src="iframeUrl"
        id="iframe"
        width="100%"
        height="500"
        style="text-align: center"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>

import {getDictionary} from "@/api/system/dictbiz";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import {getMyReportList as getList} from "@/api/system/report";
import {tree} from "@/api/system/reportCate";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";

export default {
  name: "myReport",
  components: {
    GridLayout,
    CommonTree,
  },
  data() {
    return {
      reportViewDialog: false,
      iframeUrl: '',
      iframeUrls: `${process.env.VUE_APP_BASE_RQREPORTURL}/demo/reportJsp/showReport.jsp?rpx=`,
      props: {
        label: "title",
        value: "id"
      },
      btnLoading: false,
      settingVisible: false,
      roleTreeData: [],
      roleReportObj: [],
      treeReadOnlyFlag: '',
      form: {
        parentId: '',
      },
      query: {
        reportTypeId: "",
      },
      showTreeDialog: false,
      fullscreen: false,
      node: {},   //左侧树node
      reportTypeId: '', // 点击左侧树的code
      treeData: [],
      defaultProps: {
        label: "reportCategoryName",
        value: "reportTypeId",
        key: "reportTypeId"
      },
      searchTitle: 'reportCategoryName',
      tableData: [],
      reportTypeData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission", "userInfo"]),
    searchColumns() {
      return [
        {
          prop: "reportName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.reportcenter.report.field.reportName"),
        },
        {
          prop: "reportType",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=REPORT_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.reportcenter.report.field.reportType`)
        },
      ]
    },
    treeOptions() {
      return {
        title: this.$t(`cip.plat.reportcenter.report.title.indexHeadTitle`),
        defaultExpandAll: true,
        nodeKey: 'id',
        size: 'mini',
        menu: true,
        lazy: false,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        props: {
          labelText: this.$t(`cip.plat.reportcenter.report.title.indexHeadTitle`),
          id: "id",
          label: 'reportTypeId',
          value: 'id',
          children: 'children'
        },
      };
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        linklabel: "reportName",
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.plat.reportcenter.report.field.reportName'),
            prop: 'reportName',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.reportType'),
            prop: 'reportType',
            type: 'select',
            align: "center",
            dataType: "string",
            dicUrl: `${baseUrl}REPORT_TYPE`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.sort'),
            prop: 'sort',
            align: 'right',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.reportcenter.report.field.remark'),
            prop: 'remark',
            overHidden: true
          },
        ],
      }
    },
    gridRowBtn() {
      let btn = []
      return btn;
    },
    gridHeadBtn() {
      return []
    },
  },
  methods: {
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.onLoad(this.page)
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.onLoad(this.page)
    },
    openIframe(e) {
      this.iframeUrl = this.iframeUrls + e.filePath;
      this.reportViewDialog = true;
    },
    initTree() {
      tree({parentId: 0}).then(res => {
        const {data} = res.data;
        this.treeData = data;
      });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.reportTypeId = id;
      this.page.total = 0;
      this.onLoad(this.page, {});
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.query.reportTypeId = this.reportTypeId;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach((i) => {
          this.reportTypeData.forEach((e) => {
            if (i.reportType == e.dictKey) {
              i.reportTypeName = e.dictValue;
            }
          })
        })
        console.log(data)
        this.tableData = data.records;
      });
    }
  },
  created() {
    getDictionary({code: 'REPORT_TYPE'}).then((res) => {
      this.reportTypeData = res.data.data;
      this.onLoad(this.page, {});
    })
    tree()
      .then((result) => {
        this.treeData = result.data.data;
        this.treeLoading = false;
      })
      .catch((err) => {
        this.treeLoading = true;
      });
  },
};
</script>
<style scoped lang="scss">
.common_tree_handle {
  width: 240px;
}
.el-main {
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;
}

.disflex {
  display: flex;
}

.cardBigBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .cardbox {
    width: 33.3%;
    margin-bottom: 30px;
    .card {
      width: 90%;
      margin: auto;
      cursor: pointer;
      .cardTop {
        width: calc(100% - 26px);
        padding: 12px;
        border: 1px solid #DCDFE6;

        .orderImg {
          width: 35px;
          height: 35px;
        }

        .topContent {
          margin-left: 8px;
          color: #909399;
          font-size: 14px;
          line-height: 25px;

          p {
            margin: 0;
          }

          span {
            color: #333333;
          }
        }
      }

      .cardBottom {
        width: 100%;
        background-color: #DCDFE6;
        height: 25px;
        font-size: 12px;
        line-height: 25px;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

.block {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  padding: 12px 24px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row-reverse;
}
</style>
